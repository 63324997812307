export default function Overview() {
  return (
    <div
      id="overview"
      className=" text-white flex justify-center items-center h-screen relative"
    >
      <div className="absolute top-0 left-0 h-full w-full z-[-1]">
        <img
          src="/image1.png"
          alt="header"
          className="object-cover h-full w-full"
        />
      </div>
      <div className="container flex flex-col items-center lg:gap-8 gap-4">
        <span className="lg:text-xl text-base text-center tracking-wide leading-tight">
        <span className="font-[600]">
          We carve realms from creativity, <span className="font-[800]">art, and passion</span>
          </span>
          <br />
          <br />
          Our goal is to create unique worlds that feel truly alive, with
          characters, ecosystems, and stories that respond dynamically to your
          actions.
        </span>
        <span className="lg:text-xl text-base text-center tracking-wide leading-tight">
          Exploration is limitless, and discovery is endless. We believe games
          should be more than just stories or graphics—they should be immersive
          worlds you can live in, crafted with passion and attention to detail.
        </span>
      </div>
    </div>
  );
}
