import React, { useCallback, useEffect, useState } from "react";

export const ScrollContext = React.createContext({
  scrollY: 0,
  isStarted: true,
});

const ScrollObserver = ({
  children,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [isStarted, setIsStarted] = useState(true);

  const handleScroll = useCallback(() => {
    if (isStarted) {
      setScrollY(0)
      window.scrollTo(0, 0);
    } else {
      setScrollY(window.scrollY);
    }
  }, [isStarted])

  useEffect(() => {
    const time = setTimeout(() => {
      setIsStarted(false);
    }, 1000);
    return () => clearTimeout(time);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [handleScroll, isStarted]);

  return (
    <ScrollContext.Provider value={{ scrollY, isStarted }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollObserver;