import ScrollObserver from "./utils/useScroll.jsx";
import Navbar from "./components/navbar.jsx";
import Header from "./components/header.jsx";
import Games from "./components/games.jsx";
import Overview from "./components/overview.jsx";
import Contact from "./components/contact.jsx";

function App() {
  return (
    <ScrollObserver>
      <div className="relative">
        <Navbar />
        <main>
          <Games />
          <Header />
          <Overview />
        </main>
        <footer>
          <Contact />
        </footer>
      </div>
    </ScrollObserver>
  );
}

export default App;
