import ReactPlayer from "react-player/youtube";

export default function Header() {
  return (
    <div className="relative">
      {/* <div className="h-[60vh] relative flex justify-center items-center lg:pt-24 lg:pb-14 py-16">
        <div className="container text-white flex flex-col justify-end h-full gap-8"></div>
        <div className="absolute top-0 left-0 z-[-1] w-full h-full">
          <img
            src="/image1.png"
            alt="header"
            className="w-full h-full object-cover"
          />
        </div>
      </div> */}
      <div
        className="bg-black text-white justify-center flex"
        style={{
          boxShadow: "0 -15px 20px 5px #000",
        }}
      >
        <div className="container lg:py-24 flex flex-col lg:gap-8">
          <h2 className="lg:text-6xl text-3xl tracking-wide uppercase font-bold mt-4">
            TENJIN GAMES
          </h2>
          <div className="py-4 flex lg:flex-row lg:justify-between">
            <div className="w-full">
              <p className="text-justify lg:text-4xl text-lg italic">
                Where Imagination Becomes Playable
              </p>
            </div>
            {/* <div className="lg:w-1/2 w-full aspect-video">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                width={"100%"}
                height={"100%"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
